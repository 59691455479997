import React from "react";
import { Box, Typography, Grid, Chip, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ContentCopy } from "@mui/icons-material";
import CreateTicket from "../CreateTicket";
import TicketCards from "../Cards";
import SideNav from "../../../components/sideNav";
import SingleCard from "./SingleCard";

export default function SingleTicket() {
  const [selectedRows, setSelectedRows] = React.useState([]);

  const getStatusChip = (status) => {
    const statusStyles = {
      Completed: { color: "success", bg: "#e6f4ea" },
      "In-Progress": { color: "primary", bg: "#e8f0fe" },
      Pending: { color: "warning", bg: "#fff3e0" },
    };

    return (
      <Chip
        label={status}
        size="small"
        sx={{
          backgroundColor: statusStyles[status]?.bg,
          color: status === "Pending" ? "#b45309" : "inherit",
          borderRadius: "4px",
          "& .MuiChip-label": {
            fontWeight: 500,
          },
        }}
      />
    );
  };

  const columns = [
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
      minWidth: 150,
      sortable: true,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 120,
      sortable: true,
    },
    {
      field: "ticketType",
      headerName: "Ticket Type",
      flex: 1,
      minWidth: 100,
      sortable: true,
    },
    {
      field: "ticketId",
      headerName: "Ticket ID",
      flex: 1,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {params.value}
          <IconButton size="small">
            <ContentCopy fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "assignee",
      headerName: "Assignee",
      flex: 1,
      minWidth: 120,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.value}
          size="small"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
          }}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      sortable: true,
      renderCell: (params) => getStatusChip(params.value),
    },
  ];

  const rows = [
    {
      id: 1,
      customerName: "Janet Adebayo",
      createdDate: "12 Aug 2022 - 12:25 am",
      ticketType: "Home Delivery",
      ticketId: "9348fjr73",
      assignee: "Nanny's Shop (ME)",
      status: "Completed",
    },
    {
      id: 2,
      customerName: "Samuel Johnson",
      createdDate: "12 Aug 2022 - 12:25 am",
      ticketType: "Home Delivery",
      ticketId: "9348fjr73",
      assignee: "Agent",
      status: "In-Progress",
    },
    {
      id: 3,
      customerName: "Francis Doe",
      createdDate: "12 Aug 2022 - 12:25 am",
      ticketType: "Pick Up",
      ticketId: "9348fjr73",
      assignee: "Pending",
      status: "Pending",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          pt: {
            xs: "50px",
            md: "50px",
          },
          bgcolor: "#F4F6FE",
          width: "100%",
        }}
      >
        <SideNav />

        <Box
          component="main"
          sx={{
            p: 3,
            mt: {
              xs: 0,
              md: 1,
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              mt: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    color: "#424242",
                  }}
                  gutterBottom
                >
                  Ticket Number{" "}
                  <span
                    style={{
                      color: "grey",
                    }}
                  >
                    9988776655
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
              }}
            >
              <SingleCard />
            </Box>
            <Box
              sx={{
                p: 3,
                bgcolor: "white",
                mt: "15px",
                borderRadius: "8px",
                width: {
                  xs: "75vw",
                  md: "100%",
                },
                overflowX: "auto", // Enable horizontal scrolling on mobile
              }}
            >
              <Typography sx={{ mb: 2 }}>Customer Orders</Typography>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newSelection) => {
                    setSelectedRows(newSelection);
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 5 },
                    },
                  }}
                  sx={{
                    border: "none",
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#f5f5f5",
                    },
                    "& .MuiDataGrid-cell": {
                      borderColor: "#f0f0f0",
                    },
                    "& .MuiDataGrid-root": {
                      overflow: "hidden",
                    },
                  }}
                />
              </Box>
            </Box>{" "}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
