const apiEndPointsConfig = {
  // user //

  //########  Login Details ######### //
  addLogin: "https://uae.justtawk.com/api/User/register",
  otpVarification: "https://uae.justtawk.com/api/User/otp",

  //########  Company Details ######### //
  companyDetails: "https://uae.justtawk.com/api/Onboarding/Register",
  companyFaqs: "https://uae.justtawk.com/api/Onboarding/Faq",
  getAllNumbers: "https://uae.justtawk.com/api/Onboarding/FetchNumber",
  customerCareNumber:
    "https://uae.justtawk.com/api/Onboarding/CustomerCareNumber",

  //########  Dasboard  ######### //
  onboardingDashboard: "https://uae.justtawk.com/api/Onboarding/Dashboard",
  getAllFaq: "https://uae.justtawk.com/api/Onboarding/FaqByUser",
  addFaqByImg: "http://uae.justtawk.com/api/Onboarding/FaqImage",
  editFaq: "https://uae.justtawk.com/api/Onboarding/Faq",
  getCallLogs: "https://uae.justtawk.com/api/Onboarding/CallLogs",
  getSubscriptionPlans:
    "https://uae.justtawk.com/api/Payment/getSubscriptionPlan",
  getSubscriptionPlanById:
    "https://uae.justtawk.com/api/Payment/getSubscriptionPlanDetailbyID",
  getSubscriptionResponse:
    "https://uae.justtawk.com/api/Payment/SavePaymentResponse",

  // agent //
  loginAgent: "https://uae.justtawk.com/api/Agents/Login/",
  searchByNumber: "https://uae.justtawk.com/api/Agents/searchByNumber",
};
export default apiEndPointsConfig;
