import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CreateTicket from "./CreateTicket";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

const TicketTable = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getAllTicket();
  }, []);

  const getAllTicket = async () => {
    try {
      const res = await axios.get(
        `https://uae.justtawk.com/api/Agents/ticketList/`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res?.data?.data?.TicketLists, "data");
      setData(res?.data?.data?.TicketLists || []);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  const columns = [
    { field: "TicketNumber", headerName: "Ticket Number", flex: 1 },
    { field: "UserName", headerName: "User Name", flex: 1 },
    { field: "Email", headerName: "Email", flex: 1 },
    { field: "Mobile", headerName: "Mobile", flex: 1 },
    { field: "Status", headerName: "Status", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    { field: "CreateDate", headerName: "Create Date", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <HighlightOffRoundedIcon
          sx={{ cursor: "pointer", color: "darkred" }}
          onClick={() => handleDelete(params.row)}
        />
      ),
    },
  ];

  const handleDelete = (row) => {
    console.log("Delete action for", row);
    // Implement delete functionality here
  };

  return (
    <Box>
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: 900,
            color: "#424242",
            mt: "20px",
          }}
          gutterBottom
        >
          All Tickets
        </Typography>
        <CreateTicket open={open} setOpen={setOpen} />
      </Box>
      {data?.length === 0 ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>No Tickets Found</Typography>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <DataGrid
            rows={data.map((row, index) => ({ ...row, id: index }))}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 15, 20]}
            disableSelectionOnClick
          />
        </Box>
      )}
    </Box>
  );
};

export default TicketTable;
