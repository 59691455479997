import {
  Box,
  Button,
  Container,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../../../assets/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import JusttawkBg from "../../../assets/justtawk-bg.png";
import axios from "axios";
import JusttawkLogo from "../../../assets/Justtawk-01.png";
import apiEndPointsConfig from "../../../services/apiEndPoints";

const Number = () => {
  const navigate = useNavigate();
  const [phoneDetails, setPhoneDetails] = useState([]);
  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getPhoneNumber();
  }, []);

  const getPhoneNumber = async (data) => {
    try {
      const response = await axios.get(apiEndPointsConfig.getAllNumbers);
      setPhoneDetails(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const addPhoneNumber = async (data) => {
    const access_token = token;

    try {
      const response = await axios.put(
        `${apiEndPointsConfig.customerCareNumber}/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if ((response.status = true)) {
        navigate("/loading");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedNumber: "",
    },
    validationSchema: Yup.object({
      selectedNumber: Yup.string().required("Please select a phone number"),
    }),
    onSubmit: (values) => {
      const data = {
        customerCareNo: values.selectedNumber,
      };
      console.log(data);
      addPhoneNumber(data);
    },
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const selectedPhone = phoneDetails.find((phone) => phone.did_id === value);
    console.log(selectedPhone);
    if (selectedPhone) {
      localStorage.setItem("selectedPhone", selectedPhone.did_alias);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Grid container>
        <Grid
          item
          md={9.5}
          xs={12}
          sx={{
            height: {
              xs: "100%",
              md: "100vh",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              height: {
                xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
                md: "100vh", // Full height on larger screens
              },
              backgroundImage: {
                xs: `url(${JusttawkBg})`,
              },
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) => t.palette.primary.main,
              backgroundSize: "cover", // Ensures the image covers the entire area
              backgroundPosition: "center",
              position: "relative",
              color: "white",
              display: { xs: "flex", md: "none" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
              mt: {
                xs: "-100px",
                md: "0px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: {
                    xs: 400,
                    md: 900,
                  },
                  fontSize: {
                    xs: "1.1rem",
                    md: "1.8rem",
                  },
                  mt: {
                    xs: "50px",
                    md: "0px",
                  },
                  whiteSpace: "nowrap",
                }}
              >
                Welcome to{" "}
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                    justifyContent: "center",
                    alignItems: "center",
                    p: "7px 0",
                  }}
                >
                  <img
                    src={JusttawkLogo}
                    alt="Justtawk Logo"
                    style={{
                      height: "auto", // Keep aspect ratio intact
                      maxWidth: "100%", // Ensure the image does not exceed container width
                      width: "220px", // Set a fixed width for the image
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "inline" }, // Show text on md and larger
                  }}
                >
                  Justtawk
                </Box>
              </Typography>

              <Typography
                sx={{
                  fontWeight: {
                    xs: 400,
                    md: 100,
                  },
                  fontSize: "1rem",
                }}
              >
                Your Gateway to Effortless Call Center.
              </Typography>
            </Box>
          </Grid>

          <Box
            sx={{
              p: "5px 10px",
            }}
          >
            <Box
              sx={{
                height: "8vh",
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <img src={Logo} alt="Logo" height="100%" width="200" />{" "}
            </Box>
          </Box>
          <Container>
            <Box
              sx={{
                padding: {
                  xs: "20px 0px 0 0px",
                  md: "50px 0px 0 100px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "2rem",
                    md: "2.5rem",
                  },
                  fontWeight: 900,
                  textAlign: {
                    xs: "center",
                    md: "initial",
                  },
                  lineHeight: {
                    xs: "40px",
                    md: "60px",
                  },
                }}
              >
                Select your Customer Care Number
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1rem",
                    md: "1.4rem",
                  },
                  fontWeight: 100,
                  lineHeight: {
                    md: "25px",
                  },
                  color: {
                    xs: "black",
                    md: "#6D6D6D",
                  },
                  mt: "0px",
                  textAlign: {
                    xs: "center",
                    md: "initial",
                  },
                  p: {
                    xs: "0 50px",
                    md: "0",
                  },
                }}
              >
                Start getting calls by just selecting your call center number
              </Typography>
            </Box>

            <Grid
              container
              sx={{
                mt: {
                  xs: "10px",
                  md: "20px",
                },
                padding: {
                  xs: "0 0 0 0px",
                  md: "0 0 0 100px",
                },
              }}
            >
              <Grid item xs={12} md={12}>
                <Box>
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        md: "initial",
                      },
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <FormLabel
                      component="legend"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 10,
                        pb: "10px",
                        display: {
                          xs: "none",
                          md: "flex",
                        },
                      }}
                    >
                      We found best number for your business
                    </FormLabel>

                    <form onSubmit={formik.handleSubmit}>
                      <Box
                        sx={{
                          height: {
                            xs: "25vh",
                            md: "55vh",
                          },
                          overflow: "auto",
                        }}
                      >
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="number"
                            name="selectedNumber"
                            value={formik.values.selectedNumber}
                            onChange={handleChange} // Use the custom handleChange
                          >
                            {phoneDetails.map((number) => (
                              <FormControlLabel
                                key={number?.did_id}
                                value={number?.did_id} // This is the actual value that gets submitted
                                control={<Radio />}
                                label={number?.did_alias} // This is what the user sees
                              />
                            ))}
                          </RadioGroup>
                          {formik.touched.selectedNumber &&
                          formik.errors.selectedNumber ? (
                            <Typography color="error">
                              {formik.errors.selectedNumber}
                            </Typography>
                          ) : null}
                        </FormControl>
                      </Box>

                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: {
                            xs: "center",
                            md: "flex-end",
                          },

                          p: {
                            xs: "20px 0 10px 0",
                            md: "30px 0 10px 0",
                          },
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            background: "#1A5CB0",
            padding: "0 0px 50px 0",
            display: {
              xs: "none",
              md: "initial",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: 900,
              p: "100px 20px 40px 20px",
            }}
          >
            Quick & Easy Setup
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stepper
              activeStep={3}
              orientation="vertical"
              sx={{
                color: "white",
                "& .MuiStepLabel-label": {
                  color: "white",
                },
              }}
            >
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Business Details
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Define Frequently Ask Questions
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Select Customer Care Number
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Number;
