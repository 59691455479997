import { Box, Table, Typography } from "@mui/material";
import React from "react";
import SideNav from "../../../agentComponents/sidenav";
import CreateTicket from "./CreateTicket";
import TicketTable from "./table";

const AgentTickets = () => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          p: {
            xs: "50px 0 0 0",
            md: "50px 20px",
          },
          bgcolor: "#F4F6FE",
        }}
      >
        <SideNav />
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TicketTable />
        </Box>
      </Box>
    </div>
  );
};

export default AgentTickets;
