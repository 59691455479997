import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "../../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TopArrow from "../../../../assets/Group.png";
import ManualFaqMobile from "./ManualFaqMobile";
import axios from "axios";
import JusttawkBg from "../../../../assets/justtawk-bg.png";
import JusttawkLogo from "../../../../assets/Justtawk-01.png";
import lightbulb from "../../../../assets/lightbulb.png";
import yelloBulb from "../../../../assets/lightbulb1.png";
import apiEndPointsConfig from "../../../../services/apiEndPoints";

const validationSchema = Yup.object({
  answer1: Yup.mixed().required("field is required"), // Making companyName required
  answer2: Yup.mixed().required("field is required"), // Making industryType required
  answer3: Yup.mixed().required("field is required"), // Making name required
  answer4: Yup.mixed().required("field is required"), // Making name required
  answer5: Yup.mixed().required("field is required"), // Making name required
  question1: Yup.mixed().required("field is required"), // Making companyName required
  question2: Yup.mixed().required("field is required"), // Making industryType required
  question3: Yup.mixed().required("field is required"), // Making name required
  question4: Yup.mixed().required("field is required"), // Making name required
  question5: Yup.mixed().required("field is required"), // Making name required
});

const steps = [
  {
    title: "Verify Your Account",
    description:
      "Share your business details to ensure our agents provide accurate and professional responses to your customers.",
    placeholder: "Enter countryName",
  },
  {
    title: "Setting up your call center",
    description:
      "Share your business details to ensure our agents provide accurate and professional responses to your customers.",
    placeholder: "Enter company name",
  },
  {
    title: "Base Setup",
    description:
      "Share your business details to ensure our agents provide accurate and professional responses to your customers.",
    placeholder: "Select industry type",
  },
  {
    title: "Select your company size",
    description:
      "Share your business details to ensure our agents provide accurate and professional responses to your customers.",
    placeholder: "Select company size",
  },
  {
    title: "Define Working hours",
    description:
      "Share your business details to ensure our agents provide accurate and professional responses to your customers.",
    placeholder: "Define working hours",
  },
];

const ManualFaq = ({ nextStep }) => {
  const navigate = useNavigate();
  const sections = useRef([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  const scrollToSection = (index) => {
    sections.current[index]?.scrollIntoView({ behavior: "smooth" });
  };

  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const addFaqDetails = async (data) => {
    const access_token = token;
    try {
      const response = await axios.post(
        `${apiEndPointsConfig.companyFaqs}/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/number");
      console.log("Response:", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      const data = {
        [values.question1]: values.answer1,
        [values.question2]: values.answer2,
        [values.question3]: values.answer3,
        [values.question4]: values.answer4,
        [values.question5]: values.answer5,
      };
      console.log(data);
      addFaqDetails(data);
    },
  });

  const handleNext = async () => {
    const stepFields = {
      0: ["question1"],
      1: ["question2"],
      2: ["question3"],
      3: ["question4"],
      4: ["question5"],
    };

    const currentFields = stepFields[currentStep];

    formik.setTouched(
      currentFields.reduce((acc, field) => ({ ...acc, [field]: true }), {})
    );

    const errors = await formik.validateForm();

    const hasErrors = currentFields.some((field) => errors[field]);

    if (!hasErrors) {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => {
          const nextStep = prev + 1;
          scrollToSection(nextStep);
          return nextStep;
        });
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => {
        const prevStep = prev - 1;
        scrollToSection(prevStep);
        setActiveStep((prev) => prev - 1);
        return prevStep;
      });
    }
  };

  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            height: {
              xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
              md: "100vh", // Full height on larger screens
            },
            backgroundImage: {
              xs: `url(${JusttawkBg})`,
            },
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.primary.main,
            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center",
            position: "relative",
            color: "white",
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
            mt: {
              xs: "-100px",
              md: "0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: {
                  xs: 400,
                  md: 900,
                },
                fontSize: {
                  xs: "1.1rem",
                  md: "1.8rem",
                },
                mt: {
                  xs: "50px",
                  md: "0px",
                },
                whiteSpace: "nowrap",
              }}
            >
              Welcome to{" "}
              <Box
                sx={{
                  display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                  justifyContent: "center",
                  alignItems: "center",
                  p: "7px 0",
                }}
              >
                <img
                  src={JusttawkLogo}
                  alt="Justtawk Logo"
                  style={{
                    height: "auto", // Keep aspect ratio intact
                    maxWidth: "100%", // Ensure the image does not exceed container width
                    width: "220px", // Set a fixed width for the image
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "inline" }, // Show text on md and larger
                }}
              >
                Justtawk
              </Box>
            </Typography>

            <Typography
              sx={{
                fontWeight: {
                  xs: 400,
                  md: 100,
                },
                fontSize: "1rem",
              }}
            >
              Your Gateway to Effortless Call Center.
            </Typography>
          </Box>
        </Grid>
        <Typography
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
            justifyContent: "center",
            p: "20px 0",
          }}
        >
          Step 2 - Define Frequently Ask Questions
        </Typography>
        <Grid container>
          <Grid
            item
            md={9.5}
            xs={12}
            sx={{
              height: {
                xs: "100%",
                md: "100vh",
              },
            }}
          >
            <Box
              sx={{
                p: "5px 10px",
              }}
            >
              <Box
                sx={{
                  height: "8vh",
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
              >
                <img src={Logo} alt="Logo" height="100%" width="200" />{" "}
              </Box>
            </Box>
            <Container maxWidth={"md"}>
              <Box
                sx={{
                  padding: {
                    xs: "0px 0px 10px 20px",
                    md: "90px 0px 0 0px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.6rem",
                      md: "2.5rem",
                    },
                    fontWeight: 900,

                    lineHeight: {
                      xs: "35px",
                    },
                  }}
                >
                  Share Common Customer Questions
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.2rem",
                      md: "1.4rem",
                    },
                    fontWeight: 10,
                    lineHeight: {
                      md: "25px",
                    },
                    color: "#6D6D6D",
                    mt: "15px",
                    textAlign: {
                      xs: "center",
                      md: "initial  ",
                    },
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  Help us tailor your call center scripts by sharing the
                  questions your customers frequently ask.
                </Typography>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  sx={{
                    mt: {
                      xs: "20px",
                      md: "80px",
                    },
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  <Grid item xs={12} md={9.5} sx={{}}>
                    <Box>
                      <Box
                        sx={{
                          height: {
                            xs: "30vh",
                            md: "40vh",
                          },
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          sx={{
                            width: {
                              xs: "100%",
                              md: "100%",
                              p: "00px 0 100px 0",
                            },
                          }}
                        >
                          <animated.div style={fade}>
                            {/* Step 1 */}
                            <div ref={(el) => (sections.current[0] = el)}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TextField
                                  name="question1"
                                  id="question1"
                                  variant="outlined"
                                  margin="normal"
                                  label="Write Your Question"
                                  fullWidth
                                  rows={1}
                                  multiline
                                  value={formik.values.question1}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={currentStep !== 0}
                                  error={
                                    formik.touched.question1 &&
                                    Boolean(formik.errors.question1)
                                  }
                                  helperText={
                                    formik.touched.question1 &&
                                    formik.errors.question1
                                      ? formik.errors.question1
                                      : ""
                                  }
                                  sx={{
                                    opacity: currentStep !== 0 ? 0.3 : 1,
                                    backgroundColor:
                                      currentStep !== 0 ? "#EFEFEF" : "#FFFFFF", // Adjust colors as needed
                                    "& .MuiOutlinedInput-root": {
                                      backgroundColor:
                                        currentStep === 0
                                          ? "#F8F8F8"
                                          : "#EFEFEF",
                                    },
                                  }}
                                />
                              </Box>

                              <TextField
                                name="answer1"
                                id="answer1"
                                variant="outlined"
                                margin="normal"
                                label="Write Your Answer"
                                fullWidth
                                rows={3}
                                multiline
                                value={formik.values.answer1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={currentStep !== 0}
                                error={
                                  formik.touched.answer1 &&
                                  Boolean(formik.errors.answer1)
                                }
                                helperText={
                                  formik.touched.answer1 &&
                                  formik.errors.answer1
                                    ? formik.errors.answer1
                                    : ""
                                }
                                sx={{
                                  opacity: currentStep !== 0 ? 0.3 : 1,
                                }}
                              />
                            </div>

                            {/* Step 2 */}
                            <div
                              ref={(el) => (sections.current[1] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TextField
                                  name="question2"
                                  id="question2"
                                  variant="outlined"
                                  margin="normal"
                                  label="Write Your Question"
                                  fullWidth
                                  rows={1}
                                  multiline
                                  value={formik.values.question2}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={currentStep !== 1}
                                  error={
                                    formik.touched.question2 &&
                                    Boolean(formik.errors.question2)
                                  }
                                  helperText={
                                    formik.touched.question2 &&
                                    formik.errors.question2
                                      ? formik.errors.question2
                                      : ""
                                  }
                                  sx={{
                                    opacity: currentStep !== 1 ? 0.3 : 1,
                                    backgroundColor:
                                      currentStep !== 1 ? "#EFEFEF" : "#FFFFFF", // Adjust colors as needed
                                    "& .MuiOutlinedInput-root": {
                                      backgroundColor:
                                        currentStep === 1
                                          ? "#F8F8F8"
                                          : "#EFEFEF",
                                    },
                                  }}
                                />
                              </Box>

                              <TextField
                                name="answer2"
                                id="answer2"
                                variant="outlined"
                                margin="normal"
                                label="Write Your Answer"
                                fullWidth
                                rows={3}
                                multiline
                                value={formik.values.answer2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={currentStep !== 1}
                                error={
                                  formik.touched.answer2 &&
                                  Boolean(formik.errors.answer2)
                                }
                                helperText={
                                  formik.touched.answer2 &&
                                  formik.errors.answer2
                                    ? formik.errors.answer2
                                    : ""
                                }
                                sx={{
                                  opacity: currentStep !== 1 ? 0.3 : 1,
                                }}
                              />
                            </div>

                            {/* Step 3 */}
                            <div
                              ref={(el) => (sections.current[2] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TextField
                                  name="question3"
                                  id="question3"
                                  variant="outlined"
                                  margin="normal"
                                  label="Write Your Question"
                                  fullWidth
                                  rows={1}
                                  multiline
                                  value={formik.values.question3}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={currentStep !== 2}
                                  error={
                                    formik.touched.question3 &&
                                    Boolean(formik.errors.question3)
                                  }
                                  helperText={
                                    formik.touched.question3 &&
                                    formik.errors.question3
                                      ? formik.errors.question3
                                      : ""
                                  }
                                  sx={{
                                    opacity: currentStep !== 2 ? 0.3 : 1,
                                    backgroundColor:
                                      currentStep !== 2 ? "#EFEFEF" : "#FFFFFF", // Adjust colors as needed
                                    "& .MuiOutlinedInput-root": {
                                      backgroundColor:
                                        currentStep === 2
                                          ? "#F8F8F8"
                                          : "#EFEFEF",
                                    },
                                  }}
                                />
                              </Box>

                              <TextField
                                name="answer3"
                                id="answer3"
                                variant="outlined"
                                margin="normal"
                                label="Write Your Answer"
                                fullWidth
                                rows={3}
                                multiline
                                value={formik.values.answer3}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={currentStep !== 2}
                                error={
                                  formik.touched.answer3 &&
                                  Boolean(formik.errors.answer3)
                                }
                                helperText={
                                  formik.touched.answer3 &&
                                  formik.errors.answer3
                                    ? formik.errors.answer3
                                    : ""
                                }
                                sx={{
                                  opacity: currentStep !== 2 ? 0.3 : 1,
                                }}
                              />
                            </div>

                            {/* Step 4 */}
                            <div
                              ref={(el) => (sections.current[3] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TextField
                                  name="question4"
                                  id="question4"
                                  variant="outlined"
                                  margin="normal"
                                  label="Write Your Question"
                                  fullWidth
                                  rows={1}
                                  multiline
                                  value={formik.values.question4}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={currentStep !== 3}
                                  error={
                                    formik.touched.question4 &&
                                    Boolean(formik.errors.question4)
                                  }
                                  helperText={
                                    formik.touched.question4 &&
                                    formik.errors.question4
                                      ? formik.errors.question4
                                      : ""
                                  }
                                  sx={{
                                    opacity: currentStep !== 3 ? 0.3 : 1,
                                    backgroundColor:
                                      currentStep !== 3 ? "#EFEFEF" : "#FFFFFF", // Adjust colors as needed
                                    "& .MuiOutlinedInput-root": {
                                      backgroundColor:
                                        currentStep === 3
                                          ? "#F8F8F8"
                                          : "#EFEFEF",
                                    },
                                  }}
                                />
                              </Box>

                              <TextField
                                name="answer4"
                                id="answer4"
                                variant="outlined"
                                margin="normal"
                                label="Write Your Answer"
                                fullWidth
                                rows={3}
                                multiline
                                value={formik.values.answer4}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={currentStep !== 3}
                                error={
                                  formik.touched.answer4 &&
                                  Boolean(formik.errors.answer4)
                                }
                                helperText={
                                  formik.touched.answer4 &&
                                  formik.errors.answer4
                                    ? formik.errors.answer4
                                    : ""
                                }
                                sx={{
                                  opacity: currentStep !== 3 ? 0.3 : 1,
                                }}
                              />
                            </div>

                            {/* Step 5 */}
                            <div
                              ref={(el) => (sections.current[4] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TextField
                                  name="question5"
                                  id="question5"
                                  variant="outlined"
                                  margin="normal"
                                  label="Write Your Question"
                                  fullWidth
                                  rows={1}
                                  multiline
                                  value={formik.values.question5}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={currentStep !== 4}
                                  error={
                                    formik.touched.question5 &&
                                    Boolean(formik.errors.question5)
                                  }
                                  helperText={
                                    formik.touched.question5 &&
                                    formik.errors.question5
                                      ? formik.errors.question5
                                      : ""
                                  }
                                  sx={{
                                    opacity: currentStep !== 4 ? 0.3 : 1,
                                    backgroundColor:
                                      currentStep !== 4 ? "#EFEFEF" : "#FFFFFF", // Adjust colors as needed
                                    "& .MuiOutlinedInput-root": {
                                      backgroundColor:
                                        currentStep === 4
                                          ? "#F8F8F8"
                                          : "#EFEFEF",
                                    },
                                  }}
                                />
                              </Box>

                              <TextField
                                name="answer5"
                                id="answer5"
                                variant="outlined"
                                margin="normal"
                                label="Write Your Answer"
                                fullWidth
                                rows={3}
                                multiline
                                value={formik.values.answer5}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={currentStep !== 4}
                                error={
                                  formik.touched.answer5 &&
                                  Boolean(formik.errors.answer5)
                                }
                                helperText={
                                  formik.touched.answer5 &&
                                  formik.errors.answer5
                                    ? formik.errors.answer5
                                    : ""
                                }
                                sx={{
                                  opacity: currentStep !== 4 ? 0.3 : 1,
                                }}
                              />
                            </div>
                            <div
                              ref={(el) => (sections.current[5] = el)}
                              style={{ opacity: 0 }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 4 ? "lightgrey" : "black",
                                  }}
                                >
                                  What are your payment options?
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        formik.values.question5 ===
                                        "We accept all major credit cards, PayPal, and bank transfers"
                                      }
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        formik.setFieldValue(
                                          "question5",
                                          checked
                                            ? "We accept all major credit cards, PayPal, and bank transfers"
                                            : ""
                                        );
                                      }}
                                      disabled={currentStep !== 4}
                                    />
                                  }
                                  label="Suggestion"
                                />
                              </Box>
                              <TextField
                                id="question5"
                                name="question5"
                                value={formik.values.question5}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="filled"
                                margin="normal"
                                disabled={currentStep !== 4}
                                error={
                                  formik.touched.question5 &&
                                  Boolean(formik.errors.question5)
                                }
                                helperText={
                                  formik.touched.question5 &&
                                  formik.errors.question5
                                    ? formik.errors.question5
                                    : ""
                                }
                              />
                            </div>
                          </animated.div>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      ml: {
                        xs: "0px",
                        md: "30px",
                      },
                      p: {
                        xs: "20px 0 50px 0",
                        md: "0 0 0px 0",
                      },
                      display: {
                        xs: "flex",
                        md: "initial",
                      },
                      // justifyContent: "space-between",
                    }}
                  >
                    <Box
                      color="primary"
                      onClick={handleBack}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {/* <img src={TopArrow} /> */}
                      {currentStep == 0 ? (
                        <ArrowUpwardIcon
                          sx={{
                            fontSize: "2.5rem",
                            color: "lightgrey",
                            fontWeight: 100,
                          }}
                        />
                      ) : (
                        <ArrowUpwardIcon
                          sx={{
                            fontSize: "2.5rem",
                            color: "#2C75D3",
                            fontWeight: 100,
                          }}
                        />
                      )}
                    </Box>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        handleNext();
                      }}
                      sx={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                        mt: {
                          xs: "0px",
                          md: "15px",
                        },
                        ml: {
                          xs: "30px",
                          md: "0",
                        },
                      }}
                    >
                      Save & go next
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Box
                sx={{
                  display: {
                    xs: "initial",
                    md: "none",
                  },
                }}
              >
                <ManualFaqMobile />
              </Box>
            </Container>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.5}
            sx={{
              background: "#1A5CB0",
              padding: "0 0px 50px 0",
              display: {
                xs: "none",
                md: "flex",
              },
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                color: "white",
                fontWeight: 900,
                p: "100px 20px 40px 20px",
              }}
            >
              Quick & Easy Setup
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stepper
                activeStep={1}
                orientation="vertical"
                sx={{
                  color: "white",
                  "& .MuiStepLabel-label": {
                    color: "white",
                  },
                }}
              >
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "#6198DE",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Business Details
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Define Frequently Ask Questions
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "#6198DE",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Select Customer Care Number
                    </Typography>
                  </StepLabel>
                </Step>
              </Stepper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ManualFaq;
