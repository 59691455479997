import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import SideNav from "../../components/sideNav";
import ReactEcharts from "echarts-for-react";
import CardsWidgets from "./CardsWidgets";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Timmer from "./Timmer";
import apiEndPointsConfig from "../../services/apiEndPoints";

function DashBoard() {
  const [xData, setXData] = useState([]);
  const [yData, setYData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [widgetData, setWidgetData] = useState({});
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  localStorage.getItem("selectedPhone");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.onboardingDashboard}/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("username", res.data.data.users.users_name);
      localStorage.setItem("selectedPhone", res.data.data.users.did_number);
      localStorage.setItem("startDate", res.data.data.users.users_createDate);
      setWidgetData(res?.data?.data?.calls);
      const xData = res?.data?.data?.callsDateWise?.dates;
      const yData = res?.data?.data?.callsDateWise?.totals;

      setXData(xData);
      setYData(yData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const getBarChartOptions = () => ({
    title: { text: "Total Calls" },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: xData,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Total Calls",
        type: "bar",
        data: yData,
        itemStyle: {
          color: "#3398DB",
        },
      },
    ],
  });

  if (loading) {
    return (
      <Box sx={{ pt: { xs: "50px", md: "50px" }, bgcolor: "#F4F6FE" }}>
        <Box sx={{ display: "flex" }}>
          <SideNav />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: 900,
                color: "#424242",
              }}
              gutterBottom
            >
              Dashboard
            </Typography>
            <Box sx={{ height: "0.5px", backgroundColor: "#c9c9c9" }}></Box>
            <Typography variant="h6">Loading...</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        pt: { xs: "50px", md: "50px" },
        bgcolor: "#F4F6FE",
        height: "100vh",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: {
              xs: 0,
              md: 1,
            },
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            {/* Dashboard Title */}
            <Grid item xs={12} md="auto">
              <Typography
                sx={{
                  fontSize: "1.8rem",
                  fontWeight: 900,
                  color: "#424242",
                }}
                gutterBottom
              >
                Dashboard
              </Typography>
            </Grid>

            {/* Timer */}
            <Grid item xs={12} md="auto">
              <Timmer />
            </Grid>
          </Grid>

          <Box
            sx={{
              height: "0.5px",
              mt: {
                xs: 3,
                md: 1,
              },
              backgroundColor: "#c9c9c9",
            }}
          ></Box>
          <CardsWidgets widgetData={widgetData} />
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: 300,
                }}
              >
                {xData?.length == null || yData?.length == null ? (
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: "#9e9e9e",
                      textAlign: "center",
                    }}
                  >
                    No data available
                  </Typography>
                ) : (
                  <ReactEcharts
                    option={getBarChartOptions()}
                    style={{ height: 300, width: "100%" }}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default DashBoard;
