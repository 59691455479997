import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import apiEndPointsConfig from "../../services/apiEndPoints";

const AddFaqByImage = () => {
  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      question: "",
      image: null,
    },
    validationSchema: Yup.object({
      question: Yup.string()
        .required("Question is required")
        .min(5, "Question must be at least 5 characters long"),
      image: Yup.mixed()
        .required("Image is required")
        .test("fileType", "Only image files are allowed", (value) =>
          value
            ? ["image/png", "image/jpeg", "image/jpg"].includes(value.type)
            : false
        ),
    }),
    onSubmit: (values) => {
      const data = {
        title: values?.question,
        image: values?.image,
        userid: uuid,
      };
      console.log(data);
      addFaqByImage(data);
    },
  });

  const handleFileChange = (event) => {
    formik.setFieldValue("image", event.currentTarget.files[0]);
  };
  const addFaqByImage = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("image", data.image);
      formData.append("userid", data.userid);

      const res = await axios.post(
        `${apiEndPointsConfig.addFaqByImg}/`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res);
      handleClose();
    } catch (error) {
      console.error("Error uploading FAQ:", error);
    }
  };

  return (
    <div>
      <Box>
        <Button
          variant="contained"
          sx={{
            mr: 2,
          }}
          onClick={handleOpen}
        >
          Add Faq By Image
        </Button>
      </Box>

      {/* Modal/Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs" // Sets the maximum width
        fullWidth // Makes the dialog take up the full width of the maxWidth
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Add FAQ by Image</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 1,
                width: "100%", // Ensures content spans full width
              }}
            >
              {/* Question TextField */}
              <TextField
                fullWidth
                id="question"
                name="question"
                label="Question"
                variant="outlined"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.question && Boolean(formik.errors.question)
                }
                helperText={formik.touched.question && formik.errors.question}
              />

              {/* File Upload Button */}
              <Button variant="outlined" component="label">
                Upload Image
                <input
                  type="file"
                  hidden
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleFileChange}
                />
              </Button>
              {formik.touched.image && formik.errors.image && (
                <Box
                  sx={{
                    color: "red",
                    fontSize: "0.875rem",
                    mt: -1,
                  }}
                >
                  {formik.errors.image}
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: {
                xs: "10px 25px",
                md: "10px 25px 20px 25px",
              },
            }}
          >
            <Button variant="outlined" onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddFaqByImage;
