import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AgentAccordionFaq = ({ faqDetails = [] }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filter and paginate FAQs
  const filteredData = faqDetails.filter(
    (faq) =>
      faq.faq_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      faq.faq_answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <>
      {faqDetails.length == 0 ? (
        <Box
          sx={{
            width: "100%",
            mt: {
              xs: "0",
              md: "80px",
            },
          }}
        >
          <Typography>No Details Here</Typography>
        </Box>
      ) : (
        <>
          {paginatedData.length == 0 ? (
            ""
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "22px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "22px",
                      height: "40px",
                    },
                  }}
                />
              </Box>

              {/* {paginatedData.length > 0 ? ( */}
              {paginatedData.map((faq, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Q: {faq.faq_title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>Ans: {faq.faq_answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
              {totalPages > 1 && (
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{ mt: 2 }}
                />
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AgentAccordionFaq;
