import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginImg from "../../../../src/assets/login.png";
import JusttawkLogo from "../../../../src/assets/Justtawk-01.png";
import LoginBg from "../../../../src/assets/LoginBg.jpeg";
import JusttawkBg from "../../../../src/assets/justtawk-bg.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";

export default function AgentLogin() {
  const navigate = useNavigate();

  const loginAgent = async (values) => {
    const data = {
      userid: values.userId,
      password: values.password,
    };

    try {
      const response = await axios.post(apiEndPointsConfig.loginAgent, data);
      console.log(response.data);
      localStorage.setItem("token", response?.data?.access_token);
      localStorage.setItem("agentId", response?.data?.data?.id);
      if (response.data.status === true) {
        navigate("/agent/dashboard"); // Redirect to the dashboard or desired page after successful login
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: "",
      password: "",
    },
    validationSchema: Yup.object({
      userId: Yup.string()
        .required("User ID is required")
        .min(3, "User ID must be at least 3 characters long"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters long"),
    }),
    onSubmit: (values) => {
      loginAgent(values);
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          sx={{
            mt: 0,
            height: {
              xs: "50vh",
              md: "100vh",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              height: {
                xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
                md: "100vh", // Full height on larger screens
              },
              backgroundImage: {
                xs: `url(${JusttawkBg})`,
                md: `url(${LoginBg})`,
              },
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) => t.palette.primary.main,
              backgroundSize: "cover", // Ensures the image covers the entire area
              backgroundPosition: "center",
              position: "relative",
              color: "white",
              display: { xs: "flex", sm: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
              mt: {
                xs: "-100px",
                md: "0px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: {
                    xs: 400,
                    md: 900,
                  },
                  fontSize: {
                    xs: "1.1rem",
                    md: "1.8rem",
                  },
                  mt: {
                    xs: "50px",
                    md: "0px",
                  },
                  whiteSpace: "nowrap",
                }}
              >
                Welcome to{" "}
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                    justifyContent: "center",
                    alignItems: "center",
                    p: "7px 0",
                  }}
                >
                  <img
                    src={JusttawkLogo}
                    alt="Justtawk Logo"
                    style={{
                      height: "auto", // Keep aspect ratio intact
                      maxWidth: "100%", // Ensure the image does not exceed container width
                      width: "220px", // Set a fixed width for the image
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "inline" }, // Show text on md and larger
                  }}
                >
                  Justtawk
                </Box>
              </Typography>

              <Typography
                sx={{
                  fontWeight: {
                    xs: 400,
                    md: 100,
                  },
                  fontSize: "1rem",
                }}
              >
                Your Gateway to Effortless Call Center.
              </Typography>
            </Box>

            {/* Bottom Text Block */}
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                flexDirection: "column",
                justifyContent: "center", // Center text vertically
                alignItems: "center", // Center text horizontally
                textAlign: "center", // Ensure text is centered within its Box
                mt: "auto", // Pushes the bottom block towards the bottom
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                  fontSize: {
                    xs: "1rem",
                    md: "1.8rem",
                  },
                }}
              >
                Seamless Collaboration
              </Typography>
              <Typography
                sx={{
                  fontWeight: 100,
                  fontSize: {
                    xs: "0.7rem",
                    md: "1rem",
                  },
                }}
              >
                Effortlessly work together with your <br /> team in real-time.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            sx={{
              p: { xs: "0 40px", md: "0 50px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              maxWidth: "500px",
              mx: "auto",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "2rem",
                    fontWeight: 900,
                    textAlign: {
                      xs: "center",
                      md: "initial",
                    },
                    mt: {
                      xs: "30px",
                      md: "0px",
                    },
                  }}
                >
                  Agent Login
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    display: {
                      xs: "none",
                      md: "initial",
                    },
                  }}
                >
                  Your Gateway to Effortless Call Center
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  color: "#26203B",
                  mt: {
                    xs: 2,
                    md: 4,
                  },
                }}
              >
                Please enter your User ID and Password*
              </Typography>
              <TextField
                id="userId"
                name="userId"
                variant="outlined"
                margin="normal"
                fullWidth
                placeholder="User ID"
                value={formik.values.userId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={formik.touched.userId && formik.errors.userId}
                InputProps={{
                  style: {
                    height: "40px",
                    borderRadius: "8px",
                    border: "1px solid #465FF166",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  },
                }}
              />
              <TextField
                id="password"
                name="password"
                type="password"
                variant="outlined"
                margin="normal"
                fullWidth
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  style: {
                    height: "40px",
                    borderRadius: "8px",
                    border: "1px solid #465FF166",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "#3652E2",
                  textTransform: "capitalize",
                }}
              >
                Login
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
