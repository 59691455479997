import { Button } from "@mui/material";
import React from "react";

const CreateTicket = () => {
  return (
    <div>
      <Button
        variant="contained"
        sx={{
          textTransform: "capitalize",
          p: "5px 30px",
          backgroundColor: "#5570F1",
          borderRadius: "12px",
        }}
      >
        Create Ticket
      </Button>
    </div>
  );
};

export default CreateTicket;
