import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Modal,
  Button,
  TextField,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddFaq from "./AddFaq";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddFaqByImage from "./AddFaqByImage";
import apiEndPointsConfig from "../../services/apiEndPoints";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

// Formik and Yup for form validation
const validationSchema = Yup.object({
  question: Yup.string()
    .required("Question is required")
    .min(5, "Question should be at least 5 characters long"),
  answer: Yup.string()
    .required("Answer is required")
    .min(10, "Answer should be at least 10 characters long"),
});

const AccordionFaq = () => {
  const [faqData, setFaqData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [editingFaq, setEditingFaq] = useState(null);
  const itemsPerPage = 10; // Items per page

  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getFaq();
  }, []);

  useEffect(() => {
    const result = faqData.filter(
      (faq) =>
        faq.faq_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.faq_answer.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(result);
    setCurrentPage(1); // Reset to the first page on search
  }, [searchQuery, faqData]);

  const getFaq = async () => {
    try {
      const res = await axios.get(`${apiEndPointsConfig.getAllFaq}/${uid}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      setFaqData(res?.data?.data || []);
    } catch (error) {
      console.error("Error fetching FAQ:", error);
    }
  };

  const handleOpen = (faq) => {
    setEditingFaq(faq);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingFaq(null);
  };

  const formik = useFormik({
    initialValues: {
      question: editingFaq?.faq_title || "",
      answer: editingFaq?.faq_answer || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleEditFaq({
        ...editingFaq,
        faq_title: values.question,
        faq_answer: values.answer,
      });
    },
  });

  const handleEditFaq = async (updatedFaq) => {
    const data = {
      ques: updatedFaq.faq_title,
      ans: updatedFaq.faq_answer,
    };
    try {
      await axios.put(
        `${apiEndPointsConfig.editFaq}/${updatedFaq.faq_id}`,
        data,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      getFaq();
      handleClose();
    } catch (error) {
      console.error("Error updating FAQ:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const paginate = (data, page, itemsPerPage) => {
    const startIndex = (page - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };

  const paginatedData = paginate(filteredData, currentPage, itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <TextField
          placeholder="Search"
          variant="outlined"
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            backgroundColor: "white",
            borderRadius: "22px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            height: "45px",
            "& .MuiOutlinedInput-root": {
              height: "100%",
              borderRadius: "22px",
              height: "45px",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
          }}
        >
          <AddFaqByImage />
          <AddFaq getFaq={getFaq} />
        </Box>
      </Box>
      {paginatedData &&
        paginatedData.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="">Q: {faq?.faq_title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {faq?.faq_type == "text" ? (
                <Typography>Ans: {faq?.faq_answer}</Typography>
              ) : (
                <img src={faq?.faq_answer} />
              )}

              <Box sx={{ display: "flex", mt: "20px" }}>
                <Button
                  sx={{
                    border: "1px solid #0C247B",
                    color: "#0C247B",
                  }}
                  onClick={() => handleOpen(faq)}
                  variant="outlined"
                >
                  Edit
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}

      <Pagination
        count={Math.ceil(filteredData.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
      />

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 400 }}>
          <Typography variant="h6">Edit FAQ</Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Question"
              name="question"
              fullWidth
              variant="outlined"
              margin="normal"
              value={formik.values.question}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.question && Boolean(formik.errors.question)}
              helperText={formik.touched.question && formik.errors.question}
              required
            />
            <TextField
              label="Answer"
              name="answer"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              value={formik.values.answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.answer && Boolean(formik.errors.answer)}
              helperText={formik.touched.answer && formik.errors.answer}
              required
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" color="primary" type="submit">
                Save Changes
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default AccordionFaq;
