import React from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import { ShoppingBag, ShoppingCart } from "@mui/icons-material";

export default function TicketCards() {
  const [timeFrame1, setTimeFrame1] = React.useState("This Week");
  const [timeFrame2, setTimeFrame2] = React.useState("This Week");

  const timeFrameOptions = ["This Week", "Last Week", "This Month"];

  const StatBox = ({ icon, timeFrame, setTimeFrame, children }) => (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        bgcolor: "background.default",
        borderRadius: 2,
        flex: 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <IconButton
          sx={{
            bgcolor:
              icon === ShoppingBag
                ? "rgb(255, 244, 229)"
                : "rgb(232, 244, 253)",
            borderRadius: 2,
            "&:hover": {
              bgcolor:
                icon === ShoppingBag
                  ? "rgb(255, 244, 229)"
                  : "rgb(232, 244, 253)",
            },
          }}
        >
          {React.createElement(icon, {
            sx: {
              color:
                icon === ShoppingBag
                  ? "rgb(255, 167, 38)"
                  : "rgb(33, 150, 243)",
            },
          })}
        </IconButton>
        {/* <Autocomplete
          value={timeFrame}
          onChange={(event, newValue) => setTimeFrame(newValue)}
          options={timeFrameOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              sx={{
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
              }}
            />
          )}
          sx={{
            width: 150,
            "& .MuiAutocomplete-popupIndicator": { display: "none" },
            color:"grey"
          }}
        /> */}
      </Box>

      {children}
    </Paper>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={6}>
        <StatBox
          icon={ShoppingBag}
          timeFrame={timeFrame1}
          setTimeFrame={setTimeFrame1}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography color="#8B8D97" variant="body2">
                All Ticket
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, color: "#45464E" }}
              >
                450
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography color="#8B8D97" variant="body2">
                Pending
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, color: "#45464E" }}
              >
                5
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography color="#8B8D97" variant="body2">
                Completed
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, color: "#45464E" }}
              >
                320
              </Typography>
            </Grid>
          </Grid>
        </StatBox>
      </Grid>

      <Grid item xs={6} md={6}>
        <StatBox
          icon={ShoppingCart}
          timeFrame={timeFrame2}
          setTimeFrame={setTimeFrame2}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography color="#8B8D97" variant="body2">
                Agent
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, color: "#45464E" }}
              >
                20
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography color="#8B8D97" variant="body2">
                Nanny’s Shop (Me)
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, color: "#45464E" }}
              >
                5
              </Typography>
            </Grid>
          </Grid>
        </StatBox>
      </Grid>
    </Grid>
  );
}
