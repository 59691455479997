import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import SideNav from "../../components/sideNav";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import apiEndPointsConfig from "../../services/apiEndPoints";
function Profile() {
  const [userDetails, setUserDetails] = useState([]);
  console.log(userDetails);
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.onboardingDashboard}/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setUserDetails(res?.data?.data?.users);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        pt: {
          xs: "50px",
          md: "50px",
        },
        bgcolor: "#F4F6FE",
        // height: "100vh",
      }}
    >
      <SideNav />

      <Box
        component="main"
        sx={{
          p: 3,
          mt: {
            xs: 0,
            md: 1,
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "1.8rem",
                fontWeight: 900,
                color: "#424242",
              }}
              gutterBottom
            >
              Profile
            </Typography>
            <Box sx={{ height: "0.5px", backgroundColor: "#c9c9c9" }}></Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundColor: "white",
              m: {
                xs: "0",
                md: "20px",
              },
              borderRadius: "12px",
              p: {
                xs: "0 15px 0 0",
                md: "10px 15px 10px 0",
              },
            }}
          >
            <Box>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <AccountCircleIcon
                      sx={{
                        fontSize: 45,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        p: "15px 20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        Full Name
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontWeight: 900,
                        }}
                      >
                        {userDetails?.users_name}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        p: "15px 20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        Email
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontWeight: 900,
                        }}
                      >
                        {userDetails?.users_email == ""
                          ? "No email here"
                          : userDetails?.users_email}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        p: "15px 20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        Phone Number
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontWeight: 900,
                        }}
                      >
                        {userDetails?.users_mobile}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        p: "15px 20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        Company Name
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontWeight: 900,
                        }}
                      >
                        {userDetails?.users_business_name}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        p: "15px 20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        Industry Type
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontWeight: 900,
                        }}
                      >
                        {userDetails?.users_industry}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        p: "15px 20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        Your Call Center Number
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontWeight: 900,
                        }}
                      >
                        {userDetails?.did_number}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Profile;
