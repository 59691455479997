import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Radio,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "../../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import JusttawkBg from "../../../../assets/justtawk-bg.png";
import JusttawkLogo from "../../../../../src/assets/Justtawk-01.png";
import axios from "axios";
import TaskIcon from "@mui/icons-material/Task";
import EventNoteIcon from "@mui/icons-material/EventNote";

const validationSchema = Yup.object({
  option: Yup.string().required("Please select an option"),
});

const CampaignOptionFaq = () => {
  const navigate = useNavigate();
  const initialValues = { option: "" };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          height: {
            xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
            md: "100vh", // Full height on larger screens
          },
          backgroundImage: {
            xs: `url(${JusttawkBg})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: "cover", // Ensures the image covers the entire area
          backgroundPosition: "center",
          position: "relative",
          color: "white",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
          mt: {
            xs: "-100px",
            md: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 900,
              },
              fontSize: {
                xs: "1.1rem",
                md: "1.8rem",
              },
              mt: {
                xs: "50px",
                md: "0px",
              },
              whiteSpace: "nowrap",
            }}
          >
            Welcome to{" "}
            <Box
              sx={{
                display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                justifyContent: "center",
                alignItems: "center",
                p: "7px 0",
              }}
            >
              <img
                src={JusttawkLogo}
                alt="Justtawk Logo"
                style={{
                  height: "auto", // Keep aspect ratio intact
                  maxWidth: "100%", // Ensure the image does not exceed container width
                  width: "220px", // Set a fixed width for the image
                }}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "inline" }, // Show text on md and larger
              }}
            >
              Justtawk
            </Box>
          </Typography>

          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 100,
              },
              fontSize: "1rem",
            }}
          >
            Your Gateway to Effortless Call Center.
          </Typography>
        </Box>
      </Grid>

      <Grid
        container
        sx={{
          p: {
            xs: "0 20px",
            md: "0 0",
          },
        }}
      >
        <Grid
          item
          md={9.5}
          xs={12}
          sx={{
            height: {
              xs: "100%",
              md: "100vh",
            },
          }}
        >
          <Box
            sx={{
              height: "7vh",
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            <img src={Logo} alt="Logo" height="100%" width="150" />{" "}
          </Box>
          <Container maxWidth={"md"}>
            <Box
              sx={{
                padding: {
                  xs: "5px 0px 0 0px",
                  md: "50px 0px 0 0px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "1.6rem",
                    md: "2.5rem",
                  },
                  lineHeight: {
                    xs: "30px",
                    md: "60px",
                  },
                  fontWeight: 900,
                }}
              >
                Setting up Your Call Center
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "0.95rem",
                    md: "1.4rem",
                  },
                  fontWeight: 100,
                  lineHeight: {
                    xs: "18px",
                    md: "25px",
                  },
                  color: {
                    xs: "black",
                    md: "#6D6D6D",
                  },
                  mt: "15px",
                }}
              >
                Choose how you'd like to structure your call center
                interactions: customize your questions or use our expert-crafted
                template based on your industry type
              </Typography>
            </Box>

            <>
              <Grid
                container
                sx={{
                  mt: {
                    xs: "0px",
                    md: "50px",
                  },
                }}
              >
                <Grid item xs={12} md={9.5} sx={{}}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      console.log("Selected Option:", values.option);
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <Grid
                          container
                          spacing={4}
                          sx={{
                            mt: {
                              xs: 1, // Margin for extra small devices
                              md: 3, // Larger margin for medium and larger devices
                            },
                          }}
                        >
                          <Grid item xs={12} sm={6}>
                            <Box
                              sx={{
                                height: {
                                  xs: "120px", // Reduced height for smaller screens
                                  md: "110px", // Default height for medium and larger screens
                                },
                                border: "1.5px solid darkgrey",
                                borderRadius: "4px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                transition: "transform 0.3s ease",
                                ":hover": {
                                  transform: "scale(1.05)",
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                                },
                                cursor: "pointer",
                                p: {
                                  xs: 2, // Padding for smaller devices
                                  md: 3, // Default padding for larger devices
                                },
                              }}
                              onClick={() => {
                                localStorage.setItem("selected", "true");
                                navigate(
                                  "/campaign/select-industry-type"
                                );
                              }}
                            >
                              <TaskIcon
                                fontSize="large"
                                sx={{
                                  position: "absolute",
                                  top: "-20px",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "white",
                                  borderRadius: "50%",
                                  padding: {
                                    xs: "6px", // Smaller padding for mobile
                                    md: "8px", // Default padding for larger screens
                                  },
                                  border: "1px solid rgb(113, 113, 113)",
                                  color: "grey",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "1rem", // Smaller font for mobile
                                    md: "1.3rem", // Default font size
                                  },
                                  textAlign: "center",
                                  color: "#6D6D6D",
                                  mt: {
                                    xs: 0.5, // Reduced margin for mobile
                                    md: 1, // Default margin for larger screens
                                  },
                                }}
                              >
                                Select a template of <br /> common questions
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box
                              sx={{
                                height: {
                                  xs: "120px",
                                  md: "110px",
                                },
                                border: "1.5px solid darkgrey",
                                borderRadius: "4px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                transition: "transform 0.3s ease",
                                ":hover": {
                                  transform: "scale(1.05)",
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                                },
                                cursor: "pointer",
                                p: {
                                  xs: 2,
                                  md: 3,
                                },
                              }}
                              onClick={() => {
                                localStorage.setItem("selected", "false");
                                navigate(
                                  "/campaign/select-industry-type"
                                );
                              }}
                            >
                              <EventNoteIcon
                                fontSize="large"
                                sx={{
                                  position: "absolute",
                                  top: "-20px",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "white",
                                  borderRadius: "50%",
                                  padding: {
                                    xs: "6px",
                                    md: "8px",
                                  },
                                  border: "1px solid rgb(113, 113, 113)",
                                  color: "grey",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "1rem",
                                    md: "1.3rem",
                                  },
                                  textAlign: "center",
                                  color: "#6D6D6D",
                                  mt: {
                                    xs: 0.5,
                                    md: 1,
                                  },
                                }}
                              >
                                Build your own <br /> questionnaire
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            background: "#1A5CB0",
            padding: "0 0px 50px 0",
            display: {
              xs: "none",
              md: "flex",
            },
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: 900,
              p: "100px 20px 40px 20px",
            }}
          >
            Quick & Easy Setup
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stepper
              activeStep={0}
              orientation="vertical"
              sx={{
                color: "white",
                "& .MuiStepLabel-label": {
                  color: "white",
                },
              }}
            >
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0,
                    }}
                  >
                    Business Details
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0,
                    }}
                  >
                    Define Frequently Ask Questions
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0,
                    }}
                  >
                    Select Customer Care Number
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignOptionFaq;
