import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import SideNav from "../../components/sideNav";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import apiEndPointsConfig from "../../services/apiEndPoints";
import TicketCards from "./Cards";
import Table from "./TicketTable";

function index() {
  return (
    <Box
      sx={{
        display: "flex",
        pt: {
          xs: "50px",
          md: "50px",
        },
        bgcolor: "#F4F6FE",
        width: "100%",
      }}
    >
      <SideNav />

      <Box
        component="main"
        sx={{
          p: 3,
          mt: {
            xs: 0,
            md: 1,
          },
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            mt: 2,
          }}
        >
          <Table />
        </Box>
      </Box>
    </Box>
  );
}

export default index;
