import React from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import SideNav from "../../components/sideNav";
import AccordionFaq from "./AccordionFaq";
import AddFaqByImage from "./AddFaqByImage";

function Faq() {
  return (
    <Box
      sx={{
        pt: {
          xs: "50px",
          md: "50px",
        },
        height: "100%",
        bgcolor: "#F4F6FE",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.8rem",
              fontWeight: 900,
              color: "#424242",
            }}
            gutterBottom
          >
            Solution FAQ’s
          </Typography>
          <Box>
            <AccordionFaq />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Faq;
