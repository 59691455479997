import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  TextField,
  Button,
  CardContent,
} from "@mui/material";
import SideNav from "../../../agentComponents/sidenav";
import AgentCardsWidgets from "./CardsWidgets";
import AgentAccordionFaq from "./AgentFaq";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiEndPointsConfig from "../../../services/apiEndPoints";

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function AgentDashboard() {
  const token = localStorage.getItem("token");
  const [tabValue, setTabValue] = useState(0);
  const [userDetails, setUserDetails] = useState([]);
  const [faqDetails, setFaqDetails] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getDetails = async (phoneNumber) => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.searchByNumber}/${phoneNumber}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res.data.users);
      if (res.data.status == true) {
        setFaqDetails(res.data.data);
        setUserDetails(res.data.users);
        localStorage.setItem("searchNo", phoneNumber);
        localStorage.setItem("didNumber", res?.data?.users?.did_alias);
      } else {
        setFaqDetails([]);
        setUserDetails([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDetailsByDidNo = async (phoneNumber) => {
    try {
      const res = await axios.get(
        `https://uae.justtawk.com/index.php/api/Agents/searchByDID/${phoneNumber}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status == true) {
        setFaqDetails(res.data.data);
        setUserDetails(res.data.users);
        localStorage.setItem("didNumber", phoneNumber);
      } else {
        setFaqDetails([]);
        setUserDetails([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Formik setup for "Search by customer number"
  const customerFormik = useFormik({
    initialValues: {
      customerNumber: "",
    },
    validationSchema: Yup.object({
      customerNumber: Yup.string()
        .required("Customer number is required")
        .matches(/^\d+$/, "Only numbers are allowed"),
    }),
    onSubmit: (values) => {
      getDetails(values?.customerNumber);
    },
  });

  // Formik setup for "Search by DID number"
  const didFormik = useFormik({
    initialValues: {
      didNumber: "",
    },
    validationSchema: Yup.object({
      didNumber: Yup.string()
        .required("DID number is required")
        .matches(/^\d+$/, "Only numbers are allowed"),
    }),
    onSubmit: (values) => {
      getDetailsByDidNo(values?.didNumber);
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        p: {
          xs: "50px 0 0 0",
          md: "50px 20px",
        },
      }}
    >
      <SideNav />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box sx={{ padding: "24px" }}>
          <Typography
            sx={{
              fontSize: "1.8rem",
              fontWeight: 900,
              color: "#424242",
              mt: "20px",
            }}
            gutterBottom
          >
            Agent Dashboard
          </Typography>
          {/* <AgentCardsWidgets />/ */}
          <Grid
            container
            sx={{
              mt: "20px",
            }}
          >
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {/* Tabs Section */}
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#1e1b4b",
                    },
                  }}
                >
                  <Tab
                    sx={{
                      fontSize: {
                        xs: "0.5rem",
                        md: "0.8rem",
                      },
                    }}
                    label="Search by customer number"
                  />
                  <Tab
                    sx={{
                      fontSize: {
                        xs: "0.5rem",
                        md: "0.8rem",
                      },
                    }}
                    label="Search by DID number"
                  />
                </Tabs>
              </Box>

              {/* Tab Panels */}
              <TabPanel value={tabValue} index={0}>
                <form onSubmit={customerFormik.handleSubmit}>
                  <Box sx={{ padding: "0 0 24px0" }}>
                    <div style={{ marginTop: "20px", maxWidth: "400px" }}>
                      <div style={{ marginBottom: "16px" }}>
                        <Typography variant="body1" color="textSecondary">
                          Enter Customer Number{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          id="customerNumber"
                          name="customerNumber"
                          placeholder="Enter Customer Number"
                          variant="outlined"
                          fullWidth
                          value={customerFormik.values.customerNumber}
                          onChange={customerFormik.handleChange}
                          onBlur={customerFormik.handleBlur}
                          error={
                            customerFormik.touched.customerNumber &&
                            Boolean(customerFormik.errors.customerNumber)
                          }
                          helperText={
                            customerFormik.touched.customerNumber &&
                            customerFormik.errors.customerNumber
                          }
                          sx={{
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "8px",
                            backgroundColor: "#f5f5f5",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                              borderColor: "#465ff166",
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            },
                          }}
                        />
                      </div>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                          padding: "10px",
                          backgroundColor: "#1e1b4b",
                          "&:hover": {
                            backgroundColor: "#2e2a5b",
                          },
                        }}
                      >
                        Search
                      </Button>
                    </div>
                  </Box>
                </form>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <form onSubmit={didFormik.handleSubmit}>
                  <Box sx={{ padding: "0 0 24px0" }}>
                    <div style={{ marginTop: "20px", maxWidth: "400px" }}>
                      <div style={{ marginBottom: "16px" }}>
                        <Typography variant="body1" color="textSecondary">
                          Enter DID Number{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          id="didNumber"
                          name="didNumber"
                          placeholder="Enter DID Number"
                          variant="outlined"
                          fullWidth
                          value={didFormik.values.didNumber}
                          onChange={didFormik.handleChange}
                          onBlur={didFormik.handleBlur}
                          error={
                            didFormik.touched.didNumber &&
                            Boolean(didFormik.errors.didNumber)
                          }
                          helperText={
                            didFormik.touched.didNumber &&
                            didFormik.errors.didNumber
                          }
                          sx={{
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "8px",
                            backgroundColor: "#f5f5f5",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                              borderColor: "#465ff166",
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            },
                          }}
                        />
                      </div>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                          padding: "10px",
                          backgroundColor: "#1e1b4b",
                          "&:hover": {
                            backgroundColor: "#2e2a5b",
                          },
                        }}
                      >
                        Search
                      </Button>
                    </div>
                  </Box>
                </form>
              </TabPanel>
            </Grid>
            {userDetails.length == 0 ? (
              ""
            ) : (
              <Grid item md={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "12px",
                      p: {
                        xs: "0 15px 0 0",
                        md: "10px 15px 10px 0",
                      },
                    }}
                  >
                    <Box>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Full Name
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.userName}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Email
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.email}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Phone Number
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.Mobile}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Company Name
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.businessName}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Industry Type
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.Industry}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Your Call Center Number
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.did_number}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>{" "}
          <Box
            sx={{
              mt: "50px",
            }}
          >
            <AgentAccordionFaq faqDetails={faqDetails} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AgentDashboard;
